.join{
  display: flex;
  padding:  0 2rem;
  gap: 10rem;

}
.left-j{
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.left-j>hr{
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
}
.left-j>div{
  display: flex;
  gap: 1rem;
}

.right-j{
  display: flex; 
  justify-content: center;
  align-items: flex-end;
  margin-left: 42%;

}
.email-container{
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
  
 
}

.email-container>input{
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}

::placeholder{
  color: var(--lightgray);
}

.btn-j{
  background-color: var(--orange);
  color: white;
}    

@media screen and (max-width: 768px){
  .join{
    flex-direction: column;
    gap: 1rem;
  }
  .left-j{
    font-size: xx-large;
    flex-direction: column;
    margin-left: 20px;
  }
  .right-j{
    padding: 2rem ;
    margin-left: 1px;

  }
  .email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 0.5rem 1.3rem;
  }
   .btn-j{
    padding: 2px 15px 0px 15px;
   }
  }